export default {
    abs: "mut.",
    activate: "Faollashtirish",
    add_category: "Toifa qo‘shish",
    additional_materials: "Qo‘shimcha materiallar",
    archive: "Arxiv",
    are_you_sure_you_want_to_delete_category: "Haqiqatan ham toifani o‘chirib tashlamoqchimisiz?",
    are_you_sure_you_want_to_delete_service: "Haqiqatan ham xizmatni o‘chirib tashlamoqchimisiz?",
    average_price: "O‘rtacha narxi",
    category: "Toifa",
    categories: "Toifalar",
    change_category: "Toifani o‘zgartirish",
    change_pricelist: "Pricelistni o‘zgartirish",
    complex_service: "Kompleks xizmat",
    complex_services: "Kompleks xizmatlar",
    current_value: "Joriy qiymati",
    decrease_price: "Narxni kamaytirish",
    discounted_price: "Chegirma narxi",
    doctor_reward: "Shifokor mukofoti",
    fixed_materials_expense_sum: "Materiallar xarajatlari summasi",
    import_standard_services: "Standart xizmatlar importi",
    increase_price: "Narxni oshirish",
    manager: "Administrator",
    manager_reward: "Administrator mukofoti",
    money_lost_on_discount: "Chegirmalardan pul yo'qotish",
    ndfl_code: "Kod NDFL",
    packages_cost: "To‘plamlarning tannarxi",
    personal_percent_for_assistant: "Yordamchi uchun shaxsiy foiz",
    personal_percent_for_doctor: "Shifokor uchun shaxsiy foiz",
    personal_percent_for_manager: "Administrator uchun shaxsiy foiz",
    personal_percent_for_technic: "Texnik uchun shaxsiy foiz",
    price_can_be_changed: "Davolash kartochkasida narx o‘zgarishi mumkin",
    pricelist_price: "Narxiga qarab narx",
    pricelist_short: "Prays",
    reset_selection: "Tanlovni tiklash",
    send_to_archive: "Arxivga yuborish",
    Service: "Xizmat",
    service_category: "Toifa",
    service_code: "Xizmat kodi",
    technic: "Texnik",
    technic_reward: "Texnik mukofoti"
}
