export const treatmentTemplateAdapter =
    ({
       bite,
       complaints,
       teeth_plansurvey,
       teeth_medication,
       teeth_recommendations,
       inspection,
       objectively,
       subjectively,
       diseases,
       development,
       research,
       description,
       description_doctor,
       template_id
     }, diagnoses, descriptions, additional_info) => {
      const obj = {
        bite: bite ?? null,
        complaints: complaints ?? null,
        teeth_plansurvey: teeth_plansurvey ?? null,
        teeth_medication: teeth_medication ?? null,
        teeth_recommendations: teeth_recommendations ?? null,
        inspection: inspection ?? null,
        objectively: objectively ?? null,
        subjectively: subjectively ?? null,
        diseases: diseases ?? null,
        development: development ?? null,
        research: research ?? null,
        description: description ?? null,
        description_doctor: description_doctor ?? null,
        id: template_id ? template_id : null,
        diagnoses: Object.keys(diagnoses),
        epikriz: additional_info?.epikriz ?? null,
      }
      let options = adaptDescriptions(descriptions)
      options = allObjToOptions(obj, options)
      obj.options = options
      return obj
      // return {
      //   bite: bite ?? null,
      //   complaints: complaints ?? null,
      //   teeth_plansurvey: teeth_plansurvey ?? null,
      //   teeth_medication: teeth_medication ?? null,
      //   teeth_recommendations: teeth_recommendations ?? null,
      //   inspection: inspection ?? null,
      //   objectively: objectively ?? null,
      //   subjectively: subjectively ?? null,
      //   diseases: diseases ?? null,
      //   development: development ?? null,
      //   research: research ?? null,
      //   description: description ?? null,
      //   description_doctor: description_doctor ?? null,
      //   id: template_id ? template_id : null,
      //   diagnoses: Object.keys(diagnoses),
      //   epikriz: additional_info?.epikriz ?? null,
      //   options: adaptDescriptions(descriptions)
      // }
    }

function adaptDescriptions (descriptions) {
  const options = []
  if(!descriptions) return options
  for(let tooth in descriptions) {
    for(let template in descriptions[tooth]) {
      options.push({
        text: descriptions[tooth][template],
        treatment_field: 'teeth_'+template
      })
    }
  }
  return options
}

const allObjToOptions = (obj, options) => {
  Object.entries(obj).forEach(([key, value]) => {
    if(!['epikriz', 'diagnoses', 'id', 'options'].includes(key)) {
      if(key === 'description_doctor') key = 'description'
      if(value) {
        options.push({
          text: value,
          treatment_field: key
        })
      }
    }
  })
  return options
}
