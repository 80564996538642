export const serviceAdapter =
    ({
       id,
       category_id,
       cod,
       name,
       cost,
       cost_json,
       is_binded,
       packages,
       materials,
       technics,
       doctors,
       managers,
       assistants,
       binded_cost,
       binded_cost_json,
       binded_currency,
       clinic_id,
       price_id,
       visible,
       price_can_be_changed,
       materials_fixed_cost,
       insurance_company_prices,
       dental_work_required,
       ndfl_code,
     }) => ({
      id: id ?? null,
      category_id: category_id ?? null ,
      cod: cod ?? null,
      name: name ?? null,
      cost: cost ?? 0,
      cost_json: cost_json ?? null,
      is_binded: is_binded ?? 0,
      clinic_id: clinic_id ? clinic_id : null,
      price_id: price_id ? price_id : null,
      packages: minimizePackages(packages),
      materials: minimizeMaterials(materials),
      technics: minimizeUsers(technics, cost ?? 0, clinic_id),
      doctors: minimizeUsers(doctors, cost ?? 0, clinic_id),
      managers: minimizeUsers(managers, cost ?? 0, clinic_id),
      assistants: minimizeUsers(assistants, cost ?? 0, clinic_id),
      binded_cost: binded_cost ?? null,
      binded_cost_json: binded_cost_json ? binded_cost_json : null,
      binded_currency: binded_currency ?? null,
      visible: visible ?? 0,
      price_can_be_changed: price_can_be_changed && price_can_be_changed === true ? 1 : 0,
      materials_fixed_cost: materials_fixed_cost ?? '',
      insurance_company_prices: prepareInsurance(insurance_company_prices, id),
      dental_work_required: dental_work_required ?? '',
      ndfl_code: ndfl_code ?? null
    })

function minimizePackages(packages) {
  return packages ? packages.map(({package_id}) => package_id) : []
}

function minimizeUsers(users, cost = 0, clinic_id = null) {
  let data = {}
  const percentField = 'percent'
  if(!users) return data
  users.forEach(item => {
    if(item.id) {
      const params = {}
      if(clinic_id) params.clinic_id = clinic_id
      if(item.pivot.rate_type === 'abs') {
        params.absolute = item.pivot.absolute
        params[percentField] = 0
      } else {
        let rate = item.pivot[percentField]
        if(item.pivot.rate_type === 'absolute') {
          rate = cost > 0 ? (Math.round((rate / cost) * 100000000) / 1000000) : 0
        }
        if(rate > 100) rate = 100
        params[percentField] = rate
        params.absolute = 0
      }
      data[item.id] = params
    }

  })
  return data
}

function prepareInsurance(insurance_company_prices, id) {
  if(!insurance_company_prices) return null
  const arr = []
  for (const [key, value] of Object.entries(insurance_company_prices)) {
    arr.push({
      insurance_company_id: key,
      price: value,
      service_id: id
    })
  }
  return arr
}

function minimizeMaterials(materials = []) {
  const data = {}
  if(!materials) return data
  materials.forEach(item => {
    if(item.material_id && item.pivot.amount > 0) {
      data[item.material_id] = {
        amount: item.pivot.amount,
      }
    }
  })
  return data
}

