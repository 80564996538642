<template>
  <div>
    <b-modal id="visit-pay-modal"
             size="xl"
             v-if="visit"
             :title="$t('services_payment_from')+' '+visit.readableStart"
             centered
             @show="onShow"
             @hide="onHide"
             hide-footer>
      <b-tabs nav-class="sub-menu" content-class="pt-3" v-if="!justMadePayment">
        <b-tab active :title="$t('payment_processing')">
          <VisitPayAddTooth/>
          <VisitServices v-if="initiated"
                         view="payment"
                         :visit="visit"
                         :readonly="cannotChange || cannotChangeServices"
                         :versionCount="versionCount"
                         @costTotal="costs"
                         @versionChange="versionCount++"/>
          <div v-if="costTotal == 0 && !hasServices && initiated" class="mb-4 mt-3">
            <div class="alert alert-warning">
              {{ $t('record_has_no_paid_services') }}
            </div>
          </div>
          <hr v-if="!(visit.record_teeth && visit.record_teeth.length)"/>
          <b-row class="mt-3">
            <b-col md="6" cols="12">
              <div class="d-inline-block"  v-if="!insurancePolis">
                <InputGroup type="text"
                            :renderResult="numberInputFormatter"
                            :readonly="visit.is_paid == '1' || !rights.includes('edit_discounts')"
                            @keyup="percentLimit"
                            :label="$t('additional_discount')"
                            validationId="discountinput"
                            ref="discountinput"
                            :key="`discount-key-${amountKey}`"
                            :required="true"
                            v-model="additional_discount"/>

              </div>
              <div class="d-inline-block ml-3" v-if="!insurancePolis">
                <div class="form-group">
                  <label class="form-label">{{ $t('type') }}&nbsp;&nbsp;</label>
                  <select style="width: 70px"
                          :disabled="!rights.includes('edit_discounts')"
                          class="form-control"
                          v-model="rateType">
                    <option value="absolute">{{ $store.getters.getCurrencyName }}</option>
                    <option value="percent">%</option>
                  </select>
                </div>
              </div>
              <div v-if="usesBonuses && rights.includes('accept_bonus_payments') && !insurancePolis">
                <div class="d-inline-block">
                  <InputGroup type="number"
                              class="mb-0"
                              :readonly="Number(pay_method) === 0"
                              v-if="visit.is_paid != '1'"
                              :label="$t('bonus_payment')"
                              v-model="bonus"/>
                  <div style="opacity: 0.75">
                    <small>{{ $t('bonuses') }}: {{ formatNumber(bonusForBranch) }} | {{ $t('can_be_paid_with_bonuses') }}: {{ formatNumber(maxCanBePaidWithBonuses) }}</small>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6" cols="12" class="text-right">
              <div>
                <div>
                  <label class="form-label">{{ $t('total') }}</label>: <span class="emphasized-text">{{ formatCurrency(costTotal) }}</span>
                </div>
                <div>
                  <label class="form-label">{{ $t('discount') }}</label>: <span class="emphasized-text">{{ formatCurrency(costTotal - costTotalDiscounted) }}</span>
                </div>
                <div>
                  <label class="form-label">{{ $t('additional_discount') }}</label>: <span class="emphasized-text">{{ formatCurrency(discountAbsoluteSum) }}</span>
                </div>
                <div class="mt-3">
                  <label class="form-label">{{ $t('total_to_pay') }}</label>: <span class="emphasized-text">{{ formatCurrency(totalDiscounted) }}</span>
                </div>
                <div class="mt-3" v-if="usesBonuses && bonus > 0">
                  <label class="form-label">{{ $t('to_pay_with_bonus') }}</label>: <span class="emphasized-text">{{ formatCurrency(bonus) }}</span>
                </div>
                <div>
                  <label class="form-label">{{ $t('paid') }}</label>: <span class="emphasized-text">{{ formatCurrency(visit.paidSum) }}</span>
                </div>
                <div>
                  <label class="form-label">{{ $t('debt') }}</label>: <span class="emphasized-text" :class="{'text-danger': leftToPay > 0 }">{{ formatCurrency(leftToPay) }}</span>
                </div>
              </div>
            </b-col>
          </b-row>

          <InputGroup v-model="comment"
                      class="mt-3"
                      v-if="visit.is_paid != '1'"
                      :show-errors="true"
                      validation-id="comment"
                      :label="$t('comment')"/>

          <div class="text-right mt-3">
            <button v-if="versionCount > 0"
                    :disabled="loading"
                    @click="saveWithoutPay"
                    class="btn-themed btn-themed-squared">
              {{ $t('save') }}
            </button>
          </div>

          <hr v-if="visit.is_paid != '1'"/>

          <b-row class="align-items-end" v-if="visit.is_paid != '1'">
            <b-col md="3"
                   xl="2"
                   cols="12">
              <SelectGroup :options="payMethods"
                           variant="white"
                           :label="$t('payment_form')"
                           :null-option="false"
                           :readonly="canBeProcessedWithoutPayment || amountLocked"
                           title="translatedTitle"
                           :translate="false"
                           :showlabel="true"
                           v-model="pay_method"/>
              <ValidationError validationId="form"/>
            </b-col>
            <b-col md="3"
                   xl="2"
                   cols="12"
                   v-if="pay_method == 100">
              <SelectGroup :options="parentAccounts"
                           variant="white"
                           :label="$t('family_account')"
                           :translate="false"
                           title="family_account_title"
                           :showlabel="true"
                           v-model="parentAccount"/>
            </b-col>
            <b-col md="3"
                   xl="2"
                   cols="12"
                   v-if="pay_method == 4 && insuranceLoaded">
              <SelectGroup :options="insuranceCompanies"
                           variant="white"
                           :label="$t('insurance_company')"
                           :translate="false"
                           :readonly="amountLocked"
                           title="title"
                           :showlabel="true"
                           v-model="insurance_company_id"/>
            </b-col>
            <b-col md="3"
                   xl="2"
                   cols="12"
                   v-if="pay_method == 4 && insuranceLoaded">
              <SelectGroup :options="polisesOfInsuranceCompany"
                           variant="white"
                           :label="$t('insurance_polis')"
                           :translate="false"
                           title="title"
                           :showlabel="true"
                           v-model="insurance_polis_id"/>
            </b-col>
            <b-col md="3"
                   xl="2"
                   cols="12"
                   v-if="pay_method == 5 && deposit_accounts.length > 1">
              <SelectGroup :options="deposit_accounts"
                           variant="white"
                           :label="$t('deposit_account')"
                           :translate="false"
                           :null-option="false"
                           title="title"
                           :showlabel="true"
                           v-model="deposit_account_id"/>
            </b-col>
            <b-col md="3"
                   xl="2"
                   cols="12"
                   v-if="pay_method != 7">
              <InputGroup type="text"
                          :renderResult="numberInputFormatter"
                          :label="$t('sum')"
                          validationId="amountinput"
                          ref="amountinput"
                          :readonly="amountLocked"
                          :key="`amount-key-${amountKey}`"
                          :required="true"
                          v-model="amount"/>
              <ValidationError validationId="paid"/>
              <div class="under-form-group mt-1 d-block d-md-none" v-if="patient">
                <div>
                  <small>{{ $t('deposit') }}: {{ formatCurrency(patient.advance_calculated) }}</small>
                </div>
              </div>
            </b-col>
            <b-col md cols="12" class="text-right mt-md-0 mt-2">
              <div v-if="pay_method != 7 && fiscalIsAQSI && aqsiAccounts.length > 1 && ppo"
                   class="d-inline-block mr-3 text-left">
                <SelectGroup :options="aqsiAccounts"
                             :null-option="false"
                             variant="white"
                             :label="$t('AQSI_account')"
                             :translate="false"
                             valuekey="key"
                             title="title"
                             :showlabel="true"
                             v-model="ppoAccount"/>
              </div>
              <div v-if="rights.includes('cashdesk') && pay_method != 7"
                   class="d-inline-block align-middle mr-3">
                <CheckboxGroup :label="$t('fiscal_cheque')"
                               :fid="`ppo-checkbox`"
                               class="text-left"
                               :readonly="forcePPO !== null"
                               v-model="ppo"/>
                <div v-if="kkmEnabled && ppo">
                  <CheckboxGroup :label="$t('electronic_cheque')"
                                 class="text-left"
                                 :fid="`electronic_cheque`"
                                 v-model="sendFiscalToEmail"/>
                </div>
              </div>
              <div class="form-group d-inline-block">
                <button :disabled="loading || !canBePaid || hasOpenedVisit || justMadePayment || visit.is_paid == '1' || needsToSelectPolisBeforeUsingInsurance"
                        @click="save"
                        class="btn-themed btn-success btn-themed-squared">
                  {{ hasOpenedVisit ? $t('visit_in_progress') : $t('make_payment') }}
                </button>
              </div>
            </b-col>
          </b-row>
          <div class="small text-danger" v-if="needsToSelectPolisBeforeUsingInsurance">{{ $t('visit_should_have_polis_set_in_order_to_use_insurance_payment') }}</div>
          <div v-if="patient && !insurancePolis && visit.is_paid != '1'" class="under-form-group mt-2 d-md-block d-none">
            <div class="" v-if="patient">
              <small>{{ $t('deposit') }}: {{ formatCurrency(patient.advance_calculated) }}</small>
              <DepositByAccountsTable class="small"
                                      v-if="deposit_accounts.length"
                                      :patient="patient"/>
            </div>
          </div>

        </b-tab>
        <b-tab :title="$t('payment_history')"
               v-if="visit.payments">
          <div v-if="visit.payments && visit.payments.length == 0">
            {{ $t('there_were_no_payments') }}
          </div>
          <b-row v-for="(p, pind) in visit.payments"
                 :key="`pay-row-${pind}`"
                 class="mb-2">
            <b-col cols="12" md="auto">{{ formatDateTime(p.date) }}</b-col>
            <b-col cols="6" md>{{ $t(p.payment_type) }}</b-col>
            <b-col cols="6"
                   md="6"
                   class="text-right font-weight-bold">{{ formatCurrency(p.paid) }}</b-col>
          </b-row>
        </b-tab>
      </b-tabs>

      <div class="pt-4" v-if="justMadePayment">
        <div class="pb-3">{{ $t('payment_accepted') }}:</div>
        <a @click="printReceipt"
           class="btn-themed mr-3 btn-themed-squared d-inline-block align-middle">
          {{ $t('receipt') }}
        </a>
        <a @click="printCheque"
           class="btn-themed mr-3 btn-themed-squared d-inline-block align-middle">
          {{ $t('cheque') }}
        </a>
        <a @click="printPPOCheque"
           class="btn-themed btn-themed-squared d-inline-block align-middle mr-3"
           v-if="ppo && justMadePayment.uuid && !kkmEnabled">
          {{ $t('fiscal_cheque') }}
        </a>
        <a @click="acceptAnotherPayment"
           v-if="amountNumeric < leftToPay"
           class="btn-themed btn-themed-squared btn-themed-outline align-middle d-inline-block mr-3">+ {{ $t('accept_another_payment') }}</a>
      </div>

      <hr/>
      <b-row class="justify-content-end row-narrow">
        <b-col cols="auto">
          <a @click="printInvoice"
             class="btn-themed btn-themed-squared btn-themed-outline d-inline-block">{{ $t('invoice_print') }}</a>
        </b-col>
        <b-col cols="auto">
          <b-dropdown right
                      variant="link"
                      no-caret
                      class="d-inline-block">
            <template #button-content>
            <span class="btn-themed btn-themed-squared d-block btn-themed-outline font-weight-bolder position-relative"
                  style="top: -2px;">{{ $t('akt_print') }}</span>
            </template>
            <b-dropdown-item @click="printAkt('admin')">
              {{ $t('manager') }}
            </b-dropdown-item>
            <b-dropdown-item @click="printAkt('doctor')">
              {{ $t('doctor') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-modal>
    <KKMCardPayModal :modal-resolve="payModalAction"
                     :status="kkmAcquiringStatus"/>
    <OptionsPopup v-if="optionsModalOpen"
                  :title="$t('choose_signer')"
                  @changed="modalResolve"
                  :options="signerOptions"/>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from "vuex"
import VisitServices from "@/components/parts/patients/visit/VisitServices"
import InputGroup from "@/components/form/InputGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import SelectGroup from "@/components/form/SelectGroup"
import ValidationError from "@/components/form/ValidationError"
import { EloquentService, PatientService } from "@/services/api.service"
import JwtService from "@/services/jwt.service"
import VisitPayAddTooth from "@/components/parts/patients/VisitPayAddTooth"
import { formatNumber, formatCurrency, formatDateTime } from "@/extensions/filters/filters"
import { KKMService } from "@/services/kkm.service"
import KKMCardPayModal from "@/components/parts/cashdesk/KKMCardPayModal"
import DepositByAccountsTable from "@/components/parts/patients/DepositByAccountsTable.vue"
import {copyObject} from "@/extensions/prototypes/prototypes"
import OptionsPopup from "@/components/parts/general/OptionsPopup.vue"

export default {
  name: "VisitPayModal",
  components: {
    OptionsPopup,
    DepositByAccountsTable,
    KKMCardPayModal,
    VisitPayAddTooth,
    VisitServices,
    SelectGroup,
    InputGroup,
    CheckboxGroup,
    ValidationError,
  },
  data() {
    return {
      initiated: false,
      versionCount: 0,
      // services: {},
      amount: null,
      bonus: 0,
      fixedVisitId: null,
      insurance_company_id: null,
      insurance_polis_id: null,
      additional_discount: null,
      pay_method: 1,
      ppo: false,
      costTotal: 0,
      costTotalDiscounted: 0,
      parentAccount: null,
      justMadePayment: null,
      insuranceLoaded: false,
      insuranceCompanies: [],
      insurancePolises: [],
      rateType: 'absolute',
      payMethods: [],
      depositAccounts: [],
      regex: /[^-0-9.,]/g,
      amountKey: 1,
      loaderWatcher: false,
      comment: '',
      ppoAccount: null,
      payModalAction: null,
      kkmAcquiringStatus: null,
      payMethodDetails: null,
      deposit_account_id: null,
      optionsModalOpen: false,
      sendFiscalToEmail: false,
      kkmCardPaymentId: null,
      modalResolve: () => {}
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      patient: state => state.patient.patient,
      clinic: state => state.auth.clinic,
      visit: state => state.patient.visit,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
      services: state => state.treatment.services,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
      recordTeeth: state => state.teeth.recordTeeth,
      pay_methods: state => state.auth.pay_methods,
      deposit_accounts: state => state.auth.deposit_accounts,
    }),
    ...mapGetters([
      'usesBonuses',
      'recordTimingIsOn',
      'kkmEnabled',
      'ppoSettings'
    ]),
    canBePaid() {
      if(this.canBeProcessedWithoutPayment && Number(this.pay_method) === 7) return true
      return this.amountWithBonus > 0
          && this.pay_method >= 0
          && (this.pay_method != 100 || this.parentAccount)
          && (this.amountNumeric <= this.leftToPay)
          && !(this.pay_method == 5 && this.amountNumeric > this.patient.advance_calculated)
          && !(this.pay_method == 6 && this.amountNumeric > this.bonusForBranch)
          && this.bonusValid
    },
    totalDiscounted() {
      return Math.round((this.costTotalDiscounted - this.discountAbsoluteSum) * 100) / 100
    },
    paid() {
      return !!(!this.visit || (this.visit.price > 0 && (this.visit.debt == 0 || (this.visit.payments && this.visit.payments.length))))
    },
    recordClosed() {
      return !!this.visit?.closed_visit?.id
    },
    recordOpened() {
      return !!this.visit?.opened_visit?.id
    },
    cannotChange() {
      return this.paid
      // if(this.recordTimingIsOn && !this.recordOpened) return true
      // return this.paid || (this.recordClosed && !this.recordOpened)
    },
    cannotChangeServices() {
      return !this.rights.includes('add_edit_visit_services')
    },
    hasServices() {
      return (this.visit?.actual_services?.length || 0) > 0
    },
    insurancePolis() {
      return this.visit?.insurance_polis
    },
    leftToPay() {
      return Math.round((this.totalDiscounted - this.visit.paidSum - Number(this.bonus)) * 100) / 100
    },
    parentAccounts() {
      return this.patient ? this.patient.parent_family_accounts : []
    },
    discountAbsoluteSum() {
      let percent = this.additional_discount
      if(typeof percent === 'string') {
        percent = parseFloat(percent.replaceAll(' ', ''))
        if(isNaN(percent)) percent = 0
      }
      return this.rateType === 'absolute' ? percent : (Math.round(percent * this.costTotalDiscounted) / 100)
    },
    amountNumeric() {
      if(!this.amount) return 0
      return Number(this.amount.toString().replace(this.regex, '').replace(',', '.'))
    },
    amountWithBonus() {
      return this.amountNumeric + Number(this.bonus)
    },
    bonusForBranch() {
      if(!this.patient) return 0
      return this.patient.total_bonuses
      // if(this.patient.bonuses_branched) return Number(this.patient.bonuses_branched[this.branch])
      // return 0
    },
    shareCanBePaidWithBonus() {
      const shareCanBeUsed = (this.clinic?.clinic_additional?.bonus_use || 0) / 100
      return this.totalDiscounted * shareCanBeUsed
    },
    maxCanBePaidWithBonuses() {
      return Math.min(Number(this.shareCanBePaidWithBonus) - Number(this.visit.bonusesUsedToPay), this.bonusForBranch)
    },
    bonusValid() {
      if(this.bonus === 0) return true
      if(Number(this.bonus) > Number(this.bonusForBranch)) return false
      return (Number(this.bonus) + Number(this.visit.bonusesUsedToPay)) <= this.shareCanBePaidWithBonus
    },
    hasOpenedVisit() {
      return !!this.visit.opened_visit
    },
    canBeProcessedWithoutPayment() {
      return this.hasServices && (this.totalDiscounted - (this.visit?.paidSum || 0)) === 0
    },
    aqsiAccounts() {
      if(!this.rights.includes('aqsi')) return []
      if(this.kkmEnabled) return []
      return this.ppoSettings[`aqsi_key${this.branch}`] || []
    },
    aqsiSendServices() {
      return this.ppoSettings.aqsi_send_services || false
    },
    kkmSendServices() {
      return this.ppoSettings[`kkm_send_services${this.branch}`] || false
    },
    hadPayments() {
      return (this.visit?.payments || []).length > 0
    },
    hadAqsiPayments() {
      const payments = this.visit?.payments || []
      return (payments.filter(payment => payment.fiscal_vendor === 'aqsi' && payment.local_uuid)).length > 0
    },
    hadKkmPayments() {
      const payments = this.visit?.payments || []
      return (payments.filter(payment => payment.fiscal_vendor === 'kkm')).length > 0
    },
    fiscalIsAQSI() {
      if(this.kkmEnabled) return false
      return this.rights.includes('cashdesk') && this.rights.includes('aqsi') && this.aqsiAccounts.length > 0
    },
    forcePPO() {
      if(!this.hadPayments) return null // if no payments were made, we do not force ppo
      if(this.fiscalIsAQSI && this.aqsiSendServices) {
        return this.hadAqsiPayments // means had payments and is sending services, so should be fiscal if were fiscal or not if previous payments were not fiscal
      }
      if(this.kkmEnabled && this.kkmSendServices) {
        return this.hadKkmPayments // means had payments and is sending services, so should be fiscal if were fiscal or not if previous payments were not fiscal
      }
      return null // if it's not aqsi or kkm, or they do not send services in cheque, we do not force ppo
    },
    amountLocked() {
      return !!this.insurancePolis || !!this.visit?.insurance_company_id
    },
    polises() {
      return this.patient?.insurance_polises || []
    },
    polisesOfInsuranceCompany() {
      return this.polises.filter(polis => Number(polis.insurance_company_id) === Number(this.insurance_company_id))
    },
    needsToSelectPolisBeforeUsingInsurance() {
      return Number(this.pay_method) === 4 && !this.visit?.insurance_polis_id && this.polisesOfInsuranceCompany.length
    },
    signerOptions() {
      return [
        { id: 'patient', title: this.$t('patient') },
        { id: 'representative', title: this.$t('representative_noun') },
      ]
    },
    hasRepresentative() {
      return this.patient?.representative_id || this.patient?.representative_fio
    },
  },
  methods: {
    formatNumber,
    formatCurrency,
    formatDateTime,
    ...mapActions(['getClientVisit']),
    costs(costTotal, costTotalDiscounted) {
      this.costTotal = costTotal
      this.costTotalDiscounted = costTotalDiscounted
      if(this.insurancePolis || this.visit.insurance_company_id) {
        this.insurance_company_id = this.insurancePolis?.insurance_company_id || this.visit.insurance_company_id
        this.insurance_polis_id = this.insurancePolis?.id || this.visit.insurance_polis_id
        this.pay_method = 4
        this.amount = this.leftToPay
      }
    },
    async saveWithoutPay() {
      const res = await PatientService.payForVisit(this.patient.id, this.fixedVisitId ? this.fixedVisitId : this.visit.id, {
        onlySave: true,
        changedServices: true,
        packages: this.packages,
        materials: this.materials,
        services: this.services,
      })
      this.$noty.info(this.$t('success_message'))
      this.$store.commit('incrementDataTableCounter')
      this.versionCount = 0
      this.$set(this.visit, 'actual_services', copyObject(res.data.actual_services))
    },
    async save() {
      try {
        this.loaderWatcher = true
        if(this.ppo && this.kkmEnabled) {
          this.payMethodDetails = (await EloquentService.show('pay_method', this.pay_method))?.data?.custom_data || null
          if((this.ppoSettings[`kkm_acquiring_methods${this.branch}`] || []).includes(Number(this.pay_method))) {
            this.kkmAcquiringStatus = null
            this.$bvModal.show('kkm-card-pay-modal')
            const r = await KKMService.payByPaymentCard(Number(this.amountNumeric), this.payMethodDetails?.kkm_acquiring || 0)
            if(r.error) {
              this.$noty.error(r.error)
              this.kkmAcquiringStatus = 2
            } else {
              this.kkmAcquiringStatus = r.data.Status
              this.kkmCardPaymentId = r.data.UniversalID
            }
            this.$bvModal.hide('kkm-card-pay-modal')
            const paidByCard = this.kkmAcquiringStatus === 0
            if(!paidByCard) return
          }
        }
        const res = await PatientService.payForVisit(this.patient.id, this.fixedVisitId ? this.fixedVisitId : this.visit.id, {
          additional_discount: this.discountAbsoluteSum,
          amount: this.amountNumeric,
          bonus: Number(this.bonus),
          pay_method: this.pay_method,
          deposit_account_id: this.deposit_account_id,
          family_account: this.parentAccount ? this.parentAccount : null,
          onlySave: false,
          ppo: this.ppo,
          changedServices: this.versionCount > 0,
          packages: this.packages,
          materials: this.materials,
          services: this.services,
          comment: this.comment,
          insurance_company_id: this.insurance_company_id,
          // insurance_polis_id: this.insurance_polis_id,
          additional_discount_percent: this.rateType === 'percent' ? this.additional_discount : null,
          ppoAccount: this.ppoAccount,
          kkmCardPaymentId: this.kkmCardPaymentId
        })
        this.$noty.info(this.$t('success_message'))
        this.kkmCardPaymentId = null
        if(res?.data?.id) {
          this.justMadePayment = res.data
          if(this.ppo && this.kkmEnabled) {
            await this.kkmPrintCheque()
          }
        } else {
          this.$bvModal.hide('visit-pay-modal')
        }
        this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderWatcher = false
        await this.$nextTick()
        this.$store.commit('setLoading',false)
      }
    },
    async printInvoice() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/invoice/${this.visit.id}?patientSigner=${patientSigner}`, '_blank')
    },
    async printAkt(signer) {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/akt/${this.visit.id}?signer=${signer}&patientSigner=${patientSigner}`, '_blank')
    },
    async openOptionsModal() {
      this.optionsModalOpen = true
      await this.$nextTick()
      this.$bvModal.show('options-popup-modal')
      return new Promise((resolve) => {
        this.modalResolve = resolve
      });
    },
    printReceipt() {
      if(!this.justMadePayment) return
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/receipt/'+this.justMadePayment.id, '_blank')
    },
    printCheque() {
      if(!this.justMadePayment) return
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/cheque/'+this.justMadePayment.id, '_blank')
    },
    printPPOCheque() {
      const uuid = this.justMadePayment.local_uuid || this.justMadePayment.uuid
      if(!uuid) return
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/cashdesk/cheque/'+uuid, '_blank')
    },
    async acceptAnotherPayment() {
      this.initiated = false
      await this.$nextTick()
      this.onHide(false)
      await this.onShow()
    },
    async onShow() {
      if(!this.visit || !this.patient) return
      this.fixedVisitId = this.visit.id
      this.rateType = 'absolute'
      await this.getClientVisit({
        id: this.patient.id,
        visit_id: this.visit.id
      })
      let teeth = this.visit && this.visit.record_teeth ? this.visit.record_teeth : []
      teeth = teeth.map(a => a.teeth)
      this.$store.commit('setRecordTeeth',teeth)
      if(this.visit.additional_discount_percent) {
        this.rateType = 'percent'
        this.additional_discount = this.visit.additional_discount_percent
      } else {
        this.rateType = 'absolute'
        this.additional_discount = this.visit.additional_discount
      }
      await this.$nextTick()
      await this.$store.dispatch('getDepositAccounts')
      this.initiated = true
    },
    onHide(full = true) {
      if(full) {
        this.$store.commit('setVisit', null)
        this.$store.commit('setRecordTeeth',[])
        this.$store.commit('nullifyTreatmentCard')
        this.initiated = false
      }
      this.ppo = false
      this.amount = null
      this.bonus = 0
      this.additional_discount = null
      this.justMadePayment = null
      this.parentAccount = null
      this.insuranceLoaded = false
      this.insurance_company_id = null
      this.comment = ''
      this.pay_method = 1
      this.$store.commit('cleanValidationErrors')
    },
    percentLimit() {
      let p = this.additional_discount
      if(typeof p === 'string') {
        p = parseFloat(p.replace(' ', ''))
      }
      if(p > 100 && this.rateType === 'percent') {
        this.additional_discount = 100
        this.amountKey++
        return
      }
      if(p < 0) this.additional_discount = null
    },
    numberInputFormatter(val, id) {
      val = val.replace(this.regex, '')
      val = val.replace(',', '.')
      val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if(val === this.amount && id === 'amountinput') {
        this.amountKey++
        setTimeout(() => this.$refs[id].focus(), 10)
      }
      if(val === this.additional_discount && id === 'discountinput') {
        this.amountKey++
        setTimeout(() => this.$refs[id].focus(), 10)
      }
      return val
    },
    async kkmPrintCheque() {
      const res = await KKMService.printCheque({
        totalPrice: Number(this.justMadePayment.price),
        totalPaid: Number(this.justMadePayment.paidSum),
        payId: this.justMadePayment.id,
        amount: Number(this.justMadePayment.paid),
        payMethod: Number(this.pay_method) === 100 ? 100 : this.justMadePayment.forma,
        services: copyObject(this.services),
        numDevice: this.payMethodDetails?.kkm_fiscal || 0,
        email: this.sendFiscalToEmail ? this.patient.email : null,
      })
      if(res?.error) {
        this.$noty.error(this.$t('error_printing_cheque') + ': ' + res.error)
      }
      if(res) {
        await PatientService.setUUIDForPayment(this.justMadePayment.id, {
          uuid: res.data?.CheckNumber || null,
          allData: res.data
        })
      }
    },
  },
  watch: {
    pay_method(val) {
      if(val != 100) {
        this.parentAccount = null
      }
      if(val == 4) { // insurance
        if(!this.insuranceLoaded) {
          PatientService.getInsuranceData(this.patient.id).then(res => {
            this.insuranceCompanies = res.data.insurance_companies
            this.insurancePolises = res.data.insurance_polises
            this.insuranceLoaded = true
          })
          // EloquentService.dataTable('insurance_company', 1, { pagination: 999 }).then(res => {
          //   this.insuranceCompanies = res.data.result.data
          //   this.insuranceLoaded = true
          // })
        }
      } else {
        this.insurance_company_id = null
      }
      if(val == 7) {
        this.ppo = false
      }
      if(this.kkmEnabled && (this.ppoSettings[`kkm_acquiring_methods${this.branch}`] || []).includes(Number(val))) {
        this.ppo = true
      }
      if(Number(val) === 0) {
        this.bonus = 0
      }
      if(Number(val) === 5) {
        if(this.deposit_accounts.length) {
          this.deposit_account_id = this.deposit_accounts.find(d => d.is_default === 1)?.id || this.deposit_accounts[0].id
        }
      } else {
        this.deposit_account_id = null
      }
    },
    canBeProcessedWithoutPayment(val) {
      if(val) {
        this.payMethods.push({
          id: 7,
          title: 'without_payment',
          translatedTitle: this.$t('without_payment')
        })
        this.pay_method = 7
      } else {
        this.payMethods = this.payMethods.filter(x => x.id != 7)
        if(Number(this.pay_method) === 7) this.pay_method = 1
      }
    },
    loading(val) {
      if(this.loaderWatcher && val === false) {
        this.$nextTick(() => this.$store.commit('setLoading', true))
      }
    },
    patient(val) {
      if(val) {
        if(this.parentAccounts && this.parentAccounts.length > 0 && this.rights.includes('family_account')) {
          if(!this.payMethods.filter(x => x.id == 100).length) {
            this.payMethods.push({
              id: 100,
              title: 'family_account',
              translatedTitle: this.$t('family_account')
            })
          }
        } else {
          this.payMethods = this.payMethods.filter(x => x.id != 100)
        }
      }
    },
    rateType: {
      handler(val, oldval) {
        if(oldval && this.initiated) {
          let rate = this.additional_discount
          let newRate = null
          let cost = this.costTotalDiscounted
          if(val === 'percent') {
            newRate = cost > 0 ? ((rate / cost) * 100) : 0
          }
          if(val === 'absolute') {
            newRate = cost > 0 ? (rate * cost / 100) : 0
          }
          this.additional_discount = newRate
        }
      },
      immediate: false,
    },
    ppo(val) {
      if(val && this.aqsiAccounts.length > 1) {
        this.ppoAccount = this.aqsiAccounts[0].key
      } else {
        this.ppoAccount = null
      }
      if(this.forcePPO !== null && val !== this.forcePPO) {
        this.ppo = this.forcePPO
      }
    },
    forcePPO: {
      handler(val) {
        if(val === true) {
          this.ppo = true
        }
        if(val === false) {
          this.ppo = false
        }
      },
      immediate: true
    },
    insurance_polis_id(val) {
      if(val) {
        let formData = new FormData()
        formData.append('insurance_polis_id', val)
        EloquentService.update('record', this.visit.id, formData).then(res => {
          if(res.data.id) {
            this.$store.commit('setVisit', { ...copyObject(this.visit), insurance_polis_id: val })
          }
        })
      }
    }
  },
  mounted() {
    this.payMethods = copyObject(this.pay_methods)
    if(!this.rights.includes('deposit')) {
      this.payMethods = this.payMethods.filter((obj) => obj.id !== 5)
    }
    this.payMethods = this.payMethods.filter((obj) => obj.id !== 6)
  }
}
</script>

<style scoped lang="scss">
.btn-success {
  height: 46px;
  line-height: 44px;
}
.btn-themed.show {
  opacity: 1;
}
</style>
