<template>
  <div>
    <div v-if="formTabActive === 'main'">
      <b-row class="row-narrow">
        <b-col cols="8">
          <div v-if="object.cost_json">
            <InputGroup v-for="b in branches"
                        :label="$t(field.title)+(branches.length > 1 ? ' - '+b.title : '')"
                        type="number"
                        :key="`br-cost-${b.id}`"
                        :class="{ 'd-none': object.binded_currency }"
                        :required="true"
                        v-model="object.cost_json[b.id]"/>
          </div>
          <div v-if="object.binded_cost_json">
            <div v-for="b in branches" :key="`br-bin-cost-${b.id}`">
              <InputGroup
                  :label="$t(field.title)+(branches.length > 1 ? ' - '+b.title : '')"
                  type="number"
                  @input="recalculateCostJson"
                  :class="{ 'd-none': !object.binded_currency }"
                  :required="true"
                  v-model="object.binded_cost_json[b.id]"/>
              <b-row v-if="currency && currency.value != 1" class="mb-4">
                <b-col>{{ $t('current_value') }}: {{ formatCurrency(object.cost_json[b.id]) }}</b-col>
              </b-row>
            </div>
          </div>
          <ValidationError validationId="cost"/>
        </b-col>
        <b-col cols="4" v-if="initiated">
          <SelectGroup :options="selectModels.binded_currency"
                       title="title"
                       variant="white"
                       :label="$t('currency')"
                       :nullOption="false"
                       :translate="false"
                       :showlabel="true"
                       v-model="object.binded_currency"/>
        </b-col>
      </b-row>
    </div>

    <div v-if="formTabActive === 'materials'">

      <VisitPackagesAndMaterials v-if="initiated"
                                 @packages="receivePackages"
                                 @materials="receiveMaterials"
                                 :materialsBindedToPackages="false"
      />

      <b-row v-if="rights.includes('materials')">
        <b-col md="12">
          <CheckboxGroup fid="hasMaterialsFixedCost"
                         :label="$t('fixed_materials_expense_sum')+':'"
                         v-model="hasMaterialsFixedCost"/>
        </b-col>
        <b-col md="12" v-if="hasMaterialsFixedCost">
          <InputGroup
              :label="$t('sum')"
              type="number"
              v-model="object.materials_fixed_cost"/>
        </b-col>
      </b-row>

    </div>

    <div v-if="formTabActive === 'salaries'">

      <CheckboxGroup :label="$t('personal_percent_for_technic')"
                     class="mt-2"
                     :fid="`technics-check`"
                     v-model="showTechnics"/>

      <div v-if="showTechnics">

        <ServiceUserPercentFormPart v-for="(t, tind) in object.technics"
                                    :key="`technic-row-${tind}`"
                                    :user="t"
                                    :label="$t('technic')"
                                    view="technic"
                                    :service="object"
                                    :options="selectModels.technics"
                                    @removeUser="removeUser('technics', tind)"
        />
        <div>
          <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared"
             @click="addTechnic">{{ $t('add') }}</a>
        </div>
        <hr/>
      </div>

      <CheckboxGroup :label="$t('personal_percent_for_doctor')"
                     class="mt-4"
                     :fid="`doctors-check`"
                     v-model="showDoctors"/>

      <div v-if="showDoctors">

        <ServiceUserPercentFormPart v-for="(d, dind) in object.doctors"
                                    :key="`doctor-row-${dind}`"
                                    :user="d"
                                    :label="$t('doctor')"
                                    view="doctor"
                                    :service="object"
                                    :options="selectModels.doctors"
                                    @removeUser="removeUser('doctors', dind)"
        />

        <div>
          <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared"
             @click="addDoctor">{{ $t('add') }}</a>
        </div>
        <hr/>
      </div>

      <CheckboxGroup :label="$t('personal_percent_for_manager')"
                     class="mt-4"
                     :fid="`managers-check`"
                     v-model="showManagers"/>

      <div v-if="showManagers">
        <ServiceUserPercentFormPart v-for="(m, mind) in object.managers"
                                    :user="m"
                                    :label="$t('manager')"
                                    :options="selectModels.managers"
                                    view="manager"
                                    :service="object"
                                    @removeUser="removeUser('managers', mind)"
                                    :key="`manager-row-${mind}`"/>
        <div>
          <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared"
             @click="addManager">{{ $t('add') }}</a>
        </div>
        <hr/>
      </div>

      <CheckboxGroup :label="$t('personal_percent_for_assistant')"
                     class="mt-4"
                     :fid="`assistants-check`"
                     v-model="showAssistants"/>

      <div v-if="showAssistants">
        <ServiceUserPercentFormPart v-for="(m, mind) in object.assistants"
                                    :user="m"
                                    :label="$t('assistant')"
                                    :options="selectModels.assistants"
                                    view="manager"
                                    :service="object"
                                    @removeUser="removeUser('assistants', mind)"
                                    :key="`assistant-row-${mind}`"/>
        <div>
          <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared"
             @click="addAssistant">{{ $t('add') }}</a>
        </div>
        <hr/>
      </div>

    </div>

    <div v-if="formTabActive === 'insurance'">
      <ServiceInsurancePrices :object="object"/>
    </div>

  </div>

</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"
import ValidationError from "@/components/form/ValidationError"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import VisitPackagesAndMaterials from "@/components/parts/patients/visit/VisitPackagesAndMaterials"
import ServiceUserPercentFormPart from "@/components/parts/datatable/form/services/ServiceUserPercentFormPart"
import { mapState } from "vuex"
import {formatCurrency} from "@/extensions/filters/filters"
import ServiceInsurancePrices from "@/components/parts/services/ServiceInsurancePrices.vue"

export default {
  name: "ServicesDatatableFormCard",
  components: {
    ServiceInsurancePrices,
    ServiceUserPercentFormPart,
    ValidationError,
    SelectGroup,
    InputGroup,
    CheckboxGroup,
    VisitPackagesAndMaterials
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      showTechnics: this.object.technics && this.object.technics.length > 0,
      showDoctors: this.object.doctors && this.object.doctors.length > 0,
      showManagers: this.object.managers && this.object.managers.length > 0,
      showAssistants: this.object.assistants && this.object.assistants.length > 0,
      initiated: false,
      hasMaterialsFixedCost: false
    }
  },
  computed: {
    ...mapState({
      branches: state => state.auth.branches,
      formTabActive: state => state.datatable.formTabActive,
      rights: state => state.auth.rights,
    }),
    currency() {
      if(!this.object.binded_currency) {
        return this.selectModels.binded_currency[0]
      }
      return this.selectModels.binded_currency.find(x => x.id == this.object.binded_currency)
    },
    bindedCurrency() {
      return this.object.binded_currency
    },
    bindedCost() {
      return this.object.binded_cost_json
    },
    cost() {
      return this.object.cost_json
    },
    packagesCost() {
      return this.object.packages ? this.object.packages.reduce((acc, cur) => acc + cur.cost, 0) : 0
    }
  },
  mounted() {
    this.selectModels.binded_currency.unshift({
      id: '',
      title: window.localStorage.getItem('currency'),
      value: 1,
      currency_id: null
    })
    if(!this.object.technics) {
      this.$set(this.object, 'technics', [])
    }
    if(!this.object.doctors) {
      this.$set(this.object, 'doctors', [])
    }
    if(!this.object.managers) {
      this.$set(this.object, 'managers', [])
    }
    if(!this.object.assistants) {
      this.$set(this.object, 'assistants', [])
    }
    if(this.object.materials_fixed_cost) {
      this.hasMaterialsFixedCost = true
    }
    if(!this.object.cost_json) {
      let costJsonEmpty = {}
      this.branches.forEach(b => {
        costJsonEmpty[b.id] = this.object.cost ? this.object.cost : null
      })
      this.$set(this.object, 'cost_json', costJsonEmpty)
    }
    this.emptyBindedCostJson()
    this.setPivotPercentType('technics')
    this.setPivotPercentType('doctors')
    this.setPivotPercentType('managers')
    this.setPivotPercentType('assistants')
    this.$store.commit('setPackages', this.copyObject(this.object.packages))
    this.$store.commit('setMaterials', this.copyObject(this.object.materials))
    this.initiated = true
  },
  methods: {
    formatCurrency,
    setPivotPercentType(user_field) {
      this.object[user_field].forEach((item, index) => {
        this.$set(this.object[user_field][index].pivot, 'rate_type', Number(item.pivot.absolute) > 0 ? 'abs' : 'percent')
      })
    },
    addTechnic() {
      this.object.technics.push({
        id: null,
        service_id: this.object.id,
        pivot: {
          percent: 0,
          rate_type: 'percent',
          absolute: null
        }
      })
    },
    removeTechnic(index) {
      this.object.technics.splice(index, 1)
    },
    addDoctor() {
      this.object.doctors.push({
        id: null,
        service_id: this.object.id,
        pivot: {
          percent: 0,
          rate_type: 'percent',
          absolute: null
        }
      })
    },
    removeDoctor(index) {
      this.object.doctors.splice(index, 1)
    },
    addManager() {
      this.object.managers.push({
        id: null,
        service_id: this.object.id,
        pivot: {
          percent: 0,
          rate_type: 'percent',
          absolute: null
        }
      })
    },
    addAssistant() {
      this.object.assistants.push({
        id: null,
        service_id: this.object.id,
        pivot: {
          percent: 0,
          rate_type: 'percent',
          absolute: null
        }
      })
    },
    removeUser(user_field, index) {
      this.object[user_field].splice(index, 1)
    },
    receivePackages(val) {
      this.object.packages = val
    },
    receiveMaterials(val) {
      this.object.materials = val
    },
    recalculateCostJson() {
      const firstCost = this.bindedCost?.[this.branches[0].id] || 0
      this.object.binded_cost = firstCost
      this.object.cost = firstCost * parseFloat(this.currency.value)
      this.branches.forEach(b => {
        this.$set(this.object.cost_json, b.id, (this.object.binded_cost_json?.[b.id] || 0) * parseFloat(this.currency.value))
      })
    },
    emptyBindedCostJson() {
      if(this.object.binded_cost && !this.object.binded_cost_json) {
        let bindedCostJsonEmpty = {}
        this.branches.forEach(b => {
          bindedCostJsonEmpty[b.id] = this.object.binded_cost
        })
        this.$set(this.object, 'binded_cost_json', bindedCostJsonEmpty)
      }
    }
  },
  watch: {
    bindedCurrency(val, oldval) {
      if(val && !oldval) {
        this.$set(this.object, 'binded_cost', this.object.cost)
        if(!this.object.binded_cost_json) {
          let bindedCostJsonEmpty = {}
          this.branches.forEach(b => {
            bindedCostJsonEmpty[b.id] = this.object.cost_json[b.id] ? this.object.cost_json[b.id] : this.object.cost
          })
          this.$set(this.object, 'binded_cost_json', bindedCostJsonEmpty)
        }
        return
      }
      if(val && oldval) {
        this.recalculateCostJson()
        return
      }
      if(oldval && !val) {
        this.$set(this.object, 'cost', this.object.binded_cost)
        this.$set(this.object, 'cost_json', this.object.binded_cost_json)
        this.object.binded_cost = null
        this.object.binded_cost_json = null
      }
    },
    bindedCost: {
      handler(val) {
        if(val !== null) {
          this.recalculateCostJson()
        }
      },
      deep: true,
    },
    cost: {
      handler(val) {
        if(val !== null) {
          let firstCost = val[this.branches[0].id]
          this.object.cost = firstCost
        }
      },
      deep: true,
    },
    showTechnics(val) {
      if(!val) {
        this.$set(this.object, 'technics', [])
      }
      if(val) {
        this.addTechnic()
      }
    },
    showDoctors(val) {
      if(!val) {
        this.$set(this.object, 'doctors', [])
      }
      if(val) {
        this.addDoctor()
      }
    },
    showManagers(val) {
      if(!val) {
        this.$set(this.object, 'managers', [])
      }
      if(val) {
        this.addManager()
      }
    },
    showAssistants(val) {
      if(!val) {
        this.$set(this.object, 'assistants', [])
      }
      if(val) {
        this.addAssistant()
      }
    },
    hasMaterialsFixedCost(val) {
      if(!val) {
        this.object.materials_fixed_cost = null
      }
    }
  },
  unmounted() {
    this.$store.commit('nullifyTreatmentCard')
  }
}
</script>
